import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/Layout"
import { Container } from '../components/globalLayout'

export default function () {
  return (
    <Layout>
      <Container >
        <h1>No more artworks to show here.</h1>
        <Link to="/">Go back home</Link>
      </Container>
    </Layout>
  )
}
