//This file contains the glbal style for containers and sections

import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  
  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 540px;
  }
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 720px;
  }
  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 960px;
  }
  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 1200px;
  }
  ${(props) =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`

export const Section = styled.section`
  padding: 128px 0;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 96px 0;
  }
`

// export const LayoutGrid = styled.section`
//   height: auto;
//   padding: 0 20px;
//   display: grid;
//   grid-template-columns: repeat(12, 1fr);
//   grid-template-rows: repeat(7, 1fr);
//   gap: 0px 0px;
//   grid-template-areas:
//     "header header header header header header header header header header header header"
//     "intro intro intro intro intro intro intro intro intro intro . ."
//     "intro intro intro intro intro intro intro intro intro intro . ."
//     "aside . . . . . . . . . . ."
//     "aside . . . . . . . . icon-1 icon-1 icon-1"
//     "works works works works works works works works works works works works"
//     ". . . . . . . . . . . ."
//     "about about about about about about about about about about about about";
// `
